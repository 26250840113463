var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', {
    staticClass: "d-lg-flex"
  }, [_c('b-row', {
    staticClass: "flex-grow-1"
  }, [_c('b-col', {
    staticClass: "mb-1 mb-md-0",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('label', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.$t('flightPreparation.hourPreparation')))]), _c('b-tabs', {
    staticClass: "w-100",
    attrs: {
      "id": "hour-preparation-tabs-class",
      "value": _vm.hourPreparationFilter,
      "pills": "",
      "fill": "",
      "nav-class": "mb-0",
      "active-nav-item-class": "tab-active"
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:hourPreparationFilter', val);
      }
    }
  }, _vm._l(_vm.hourPreparationOptions, function (item, index) {
    return _c('b-tab', {
      key: index,
      staticClass: "bg-.nav-pills .nav-link.active",
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_vm._v(" " + _vm._s(item.label) + " ")];
        },
        proxy: true
      }], null, true)
    });
  }), 1)], 1), _c('b-col', {
    staticClass: "mb-1 mb-md-0 flex-grow-1",
    staticStyle: {
      "translate": "0 3px"
    },
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('label', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.$t('flightPreparation.searchText')))]), _c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "SearchIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "value": _vm.searchTextFilter,
      "type": "search",
      "maxlength": "255",
      "debounce": "100",
      "disabled": !_vm.canAccess('booking.searchBooking'),
      "trim": "",
      "placeholder": "Mã đặt chỗ, tên hành khách, hành trình"
    },
    on: {
      "input": function input(value) {
        return _vm.handleDebounceSearchText(value);
      }
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "mb-1 mb-md-0 flex-grow-1",
    staticStyle: {
      "translate": "0 3px",
      "z-index": "4"
    },
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('label', {
    staticClass: "font-weight-bolder mb-25"
  }, [_vm._v(_vm._s(_vm.$t('createdBy')))]), _c('v-select', {
    staticClass: "form-control p-0 border-0",
    attrs: {
      "id": "created-by-select",
      "options": _vm.createdByOptions,
      "label": "firstName",
      "searchable": "",
      "clearable": false,
      "filterable": false,
      "value": _vm.createdByFilter,
      "loading": _vm.isLoading,
      "placeholder": _vm.$t('placeholderSelect'),
      "reduce": function reduce(val) {
        return val;
      }
    },
    on: {
      "open": _vm.handleOpenCreatedBy,
      "search": _vm.handleSearchCreatedBy,
      "input": function input(val) {
        return _vm.$emit('update:createdByFilter', val);
      }
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function fn(_ref) {
        var firstName = _ref.firstName,
          lastName = _ref.lastName,
          username = _ref.username;
        return [_c('div', {
          staticClass: "w-100 d-flex justify-content-between text-truncate"
        }, [_c('span', {
          staticClass: "font-weight-bold d-block text-nowrap",
          class: _vm.isMobileView ? 'font-small-2' : ''
        }, [_vm._v(" " + _vm._s(lastName) + " " + _vm._s(firstName) + " (" + _vm._s(username) + ") ")])])];
      }
    }, {
      key: "spinner",
      fn: function fn(_ref2) {
        var loading = _ref2.loading;
        return [loading ? _c('div', {
          staticClass: "vs__spinner",
          staticStyle: {
            "border-left-color": "rgba(88, 151, 251, 0.71)"
          }
        }) : _vm._e()];
      }
    }, {
      key: "option",
      fn: function fn(_ref3) {
        var firstName = _ref3.firstName,
          lastName = _ref3.lastName,
          username = _ref3.username;
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "9"
          }
        }, [_c('span', {
          staticClass: "font-weight-bold d-block text-nowrap",
          class: _vm.isMobileView ? 'font-small-1' : ''
        }, [_vm._v(" " + _vm._s(lastName) + " " + _vm._s(firstName) + " (" + _vm._s(username) + ") ")])])], 1)];
      }
    }])
  })], 1)], 1), _c('div', {
    staticClass: "ml-lg-1 d-flex-center align-items-end"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "font-weight-bolder mt-md-0",
    attrs: {
      "variant": "outline-danger"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('reset');
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50 text-danger",
    attrs: {
      "icon": "TrashIcon"
    }
  }), _c('span', {
    staticClass: "align-middle text-danger font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.$t('clear')) + " ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }