<template>
  <b-modal
    id="id-modal-reservation-sms"
    :title="$t('reservation.exportSms')"
    title-class="text-airline font-medium-4 font-weight-bolder"
    body-class="p-1"
    centered
    no-close-on-backdrop
    @show="onShowModalSms()"
  >
    <div class="text-danger">
      {{ $t('reservation.sms.note') }}
    </div>

    <b-tabs
      v-model="tabIndex"
      lazy
    >
      <b-tab
        active
        :title="$t('reservation.sms.vnWithAccent')"
      >
        <b-form-textarea
          v-model="smsContents[0]"
          rows="16"
          style="font-size: 16px; line-height: 24px;"
        />
      </b-tab>

      <b-tab :title="$t('reservation.sms.vnNoAccent')">
        <b-form-textarea
          v-model="smsContents[1]"
          rows="16"
          style="font-size: 16px; line-height: 24px;"
        />
      </b-tab>

      <b-tab :title="$t('reservation.sms.en')">
        <b-form-textarea
          v-model="smsContents[2]"
          rows="16"
          style="font-size: 16px; line-height: 24px;"
        />
      </b-tab>
    </b-tabs>

    <template #modal-footer>
      <b-button
        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
        variant="outline-danger"
        pill
        @click="$root.$emit('bv::hide::modal', 'id-modal-reservation-sms')"
      >
        {{ $t('customer.cancel') }}
      </b-button>

      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="btn-gradient width-150 mt-lg-25"
        pill
        :disabled="disabledCopy"
        @click="copySmsContentToClipboard()"
      >
        {{ $t('reservation.copy') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  ref,
} from '@vue/composition-api'
import {
  BModal,
  BTabs,
  BTab,
  BFormTextarea,
  BButton,
} from 'bootstrap-vue'
import { useClipboard } from '@vueuse/core'

import store from '@/store'
import {
  getBaggagesByBookingClass,
  getHandBaggage,
  getFreeBaggage,
} from '@/views/apps/configs/ticket/useSegmentBaggage'

import {
  formatCurrency,
  convertISODateTime,
  getLongWeekdayFromIso, resolveAirlineFlightNumber,
  cutHonorificFromFirstName,
} from '@core/utils/filter'
import { removeAccents } from '@core/comp-functions/forms/formatter-input'

import useReservationHandle from '@reservation/useReservationHandle'

export default {
  name: 'ModalSms',

  components: {
    BModal,
    BTabs,
    BTab,
    BFormTextarea,
    BButton,
  },

  props: {
    ticketData: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  setup(props) {
    const {
      // eslint-disable-next-line no-unused-vars
      getBaggageInBookingDetail, resolveSsrData, resolveBaggageBySegment, resolveSegmentData,
    } = useReservationHandle()
    const tabIndex = ref(0)
    const smsContents = ref(['', '', ''])
    const disabledCopy = ref(false)

    const { copy } = useClipboard()

    function copySmsContentToClipboard() {
      disabledCopy.value = true
      setTimeout(() => {
        disabledCopy.value = false
      }, 1000)

      copy(smsContents.value[tabIndex.value])
        .then(() => {
          this.$root.toastSuccess('reservation.sms.copySuccess')
        })
        .catch(error => {
          this.$root.toastError('reservation.sms.copyFail')
          console.error(this.$t('reservation.sms.copyFail'), error)
        })
    }

    // const flights = ref(null)
    // const paxs = ref(null)
    // const price = ref(null)
    // const totalVat = ref(0)
    // const totalPayment = ref(0)

    // function setTicketsData() {
    //   const {
    //     flights: flightsValue,
    //     paxs: paxsValue,
    //     price: priceValue,
    //   } = useParseTicketData(props.ticketData)

    //   flights.value = flightsValue
    //   paxs.value = paxsValue
    //   price.value = priceValue
    //   totalVat.value = Math.round(price.value.totalAmountBeforeTax * price.value.vatRateValue)
    //   totalPayment.value = price.value.totalAmountBeforeTax + totalVat.value
    // }

    function getAirportByAirportCode(airportCode) {
      return store.getters['app-flight-preparation/getAirportByAirportCode'](airportCode)
    }

    function getAirlineNameByCode(code) {
      return store.getters['globalConfig/getAirlineNameByCode'](code)
    }

    // ANCHOR: OLD function - by binhbdn
    // callback function t: translation function
    // function getSmsContent(t, isVietnamese, isRemoveAccents = false) {
    //   const forcedLocale = isVietnamese ? 'vi' : 'en'
    //   let result = ''

    //   // paxs fullname
    //   paxs.value.forEach(item => {
    //     const fullName = isVietnamese
    //       ? `${item.passenger.lastName} ${item.passenger.firstName}`
    //       : `${item.passenger.firstName} ${item.passenger.lastName}`

    //     result += `${fullName}\n`
    //   })
    //   // result += '\n'

    //   // flights info
    //   // result += '-------***-------\n'
    //   flights.value.forEach(flight => {
    //     flight.segments.forEach(segment => {
    //       const textAirline = t('promotionAg.tableTitle.airline')
    //       result += `${textAirline}: ${this.getAirlineNameByCode(segment.airline)}\n`

    //       if (segment.operating) {
    //         const textCodeshare = t('ticket.flightsInfo.codeshareWith')
    //         result += `${textCodeshare}: ${this.getAirlineNameByCode(segment.operating)}\n`
    //       }

    //       const textReservationCode = t('reservation.code')
    //       result += `${textReservationCode}: ${flight.bookingCode}\n`

    //       // name of departure airport
    //       const departureAirportObj = this.getAirportByAirportCode(segment.from)
    //       let departureAirportName
    //       if (departureAirportObj.group === 'VIỆT NAM' || departureAirportObj.group === 'VIETNAM') {
    //         departureAirportName = `${t(`vnAirports.${segment.from}`)} (${segment.from})`
    //       } else {
    //         departureAirportName = departureAirportObj.name
    //       }

    //       // name of arrival airport
    //       const arrivalAirportObj = this.getAirportByAirportCode(segment.to)
    //       let arrivalAirportName
    //       if (arrivalAirportObj.group === 'VIỆT NAM' || arrivalAirportObj.group === 'VIETNAM') {
    //         arrivalAirportName = `${t(`vnAirports.${segment.to}`)} (${segment.to})`
    //       } else {
    //         arrivalAirportName = arrivalAirportObj.name
    //       }

    //       const textRoute = t('reservation.route')

    //       result += `* ${textRoute} ${departureAirportName} - ${arrivalAirportName}\n`

    //       const textFlight = t('ticket.flightsInfo.flight')
    //       result += `${textFlight} ${segment.flight.replace('-', '')}\n`

    //       const textDepartTime = t('reservation.at')
    //       const textDepartDate = t('reservation.date')
    //       const departWeekday = isVietnamese
    //         ? t(segment.departWeekday)
    //         : segment.departWeekday
    //       result += `${textDepartTime} ${segment.departTime} - ${textDepartDate} ${segment.departDate} (${departWeekday})\n`
    //       result += '-------***-------\n'
    //       const textHandBaggage = t('ticket.flightsInfo.handBaggage')
    //       result += `${textHandBaggage}: ${getBaggageInBookingDetail(segment.segmentNotModified, props.ticketData) ? getBaggageInBookingDetail(segment.segmentNotModified, props.ticketData).carryBag : this.getHandBaggage(segment)}\n`

    //       const textFreeBaggage = t('ticket.flightsInfo.freeBaggage')
    //       const freeBag = getBaggageInBookingDetail(segment.segmentNotModified, props.ticketData) ? getBaggageInBookingDetail(segment.segmentNotModified, props.ticketData).checkinBag : this.getFreeBaggage(segment, forcedLocale)
    //       const freeBaggage = isRemoveAccents
    //         ? removeAccents(freeBag)
    //         : freeBag
    //       result += `${textFreeBaggage}: ${freeBaggage}\n`

    //       if (segment.transitTime) {
    //         result += '-------------------\n'
    //         const textTransitIn = t('ticket.flightsInfo.transitIn')
    //         const textTransitTime = t('ticket.flightsInfo.transitTime')
    //         result += `${textTransitIn} ${segment.to} - ${textTransitTime}: ${segment.transitTime}\n`
    //         result += '-------------------\n'
    //       }
    //     })

    //     result += '-------***-------\n'
    //   })

    //   // baggage with fee (hành lý có phí)
    //   if (paxs.value?.[0]?.bags?.[0]) {
    //     const textBaggageWithFee = t('reservation.baggageWithFee')
    //     result += `${textBaggageWithFee}:\n`
    //     // result += '-------***-------\n'

    //     paxs.value.forEach((item, itemIndex) => {
    //       if (item.bags && item.bags.length) {
    //         if (itemIndex > 0) {
    //           result += '-------------------\n'
    //         }
    //         const fullName = isVietnamese
    //           ? `${item.passenger.lastName} ${item.passenger.firstName}`
    //           : `${item.passenger.firstName} ${item.passenger.lastName}`

    //         result += `${fullName}\n`

    //         item.bags.forEach(bag => {
    //           if (bag.xBagName) {
    //             let textXBag = ''
    //             textXBag += `${bag.trip}: `
    //             textXBag += `${bag.quantity} `
    //             textXBag += `${t('ticket.pack')} `
    //             if (bag.xBagOversize) {
    //               textXBag += `${t('ticket.oversized')} `
    //             }
    //             textXBag += bag.xBagName

    //             result += `${textXBag}\n` // for example: HAN-SGN: 1 gói 40kg
    //           }
    //         })
    //       }
    //     })

    //     result += '-------***-------\n'
    //   }

    //   // total amount
    //   const textTotalAmount = t('reservation.totalAmount')
    //   result += `${textTotalAmount}: ${formatCurrency(totalPayment.value)} ${price.value.currency}\n`

    //   // additional notes
    //   const textNote1 = t('reservation.sms.note1')
    //   const textNote2 = t('reservation.sms.note2')
    //   result += `${textNote1}\n${textNote2}`

    //   return result
    // }

    // SECTION: NEW function - by duclt
    function convertFlatTicketData(ticketData) {
      const array = [ticketData]
      if (ticketData?.roundtripBooking) {
        array.push(ticketData?.roundtripBooking)
      }
      if (ticketData?.splitItineraries && ticketData?.multiCityBookings && ticketData?.multiCityBookings.length) {
        array.push(...ticketData?.multiCityBookings)
      }

      try {
        array.sort((a, b) => {
          const departureA = new Date(a.itineraries[0][0].departure.at)
          const departureB = new Date(b.itineraries[0][0].departure.at)
          return departureA - departureB
        })
      } catch (error) {
        smsContents.value = [
          'Chuyến bay đã bị huỷ',
          'Chuyen bay da bi huy',
          'This flight have been cancelled',
        ]
        return null
      }

      return array
    }

    function getSmsContent(t, isVietnamese, isRemoveAccents = false) {
      const ticketData = convertFlatTicketData(props.ticketData)
      if (!ticketData) return 'Chuyến bay đã bị huỷ'
      let result = ''

      ticketData.forEach(ticket => {
        // ANCHOR pax
        const paxs = ticket.paxLists.map(pax => {
          const { firstName, honorific } = cutHonorificFromFirstName(pax.firstName)
          const title = pax.title || honorific
          const paxType = pax.paxType
          // let bags = null
          // if (paxType === 'ADULT') {
          //   bags = allSsrBag.filter(item => item.paxId === pax.paxId)
          // }

          return {
            passenger: {
              paxType,
              title,
              firstName,
              lastName: pax.lastName,
            },
            // bags,
          }
        })
        paxs.forEach(item => {
          const fullName = isVietnamese
            ? `${item.passenger.lastName} ${item.passenger.firstName}`
            : `${item.passenger.firstName} ${item.passenger.lastName}`

          result += `${fullName}\n`
        })

        // ANCHOR airlines
        const airline = ticket.airlines.split(' | ')[0]
        const textAirline = t('promotionAg.tableTitle.airline')
        result += `${textAirline} ${getAirlineNameByCode(airline)}\n`

        // ANCHOR booking Code
        const bookingCode = ticket.bookingCode
        const textReservationCode = t('reservation.code')
        result += `${textReservationCode}: ${bookingCode}\n`

        // ANCHOR: itineraries
        ticket.itineraries.forEach(trip => {
          trip.forEach(segment => {
            // name of departure airport
            const departureAirportObj = getAirportByAirportCode(segment.departure.iataCode)
            let departureAirportName
            if (departureAirportObj?.iata && departureAirportObj?.cityName && departureAirportObj?.cityEnName && departureAirportObj?.countryName && departureAirportObj?.countryEnName) {
              departureAirportName = `${departureAirportObj.cityName} (${departureAirportObj.iata}), ${departureAirportObj?.countryName}`
            } else if (departureAirportObj.group === 'VIỆT NAM' || departureAirportObj.group === 'VIETNAM') {
              departureAirportName = `${t(`vnAirports.${segment.departure.iataCode}`)} (${segment.departure.iataCode})`
            } else {
              departureAirportName = `${departureAirportObj.name} (${departureAirportObj.iata})`
            }

            // name of arrival airport
            const arrivalAirportObj = getAirportByAirportCode(segment.arrival.iataCode)
            // console.log({ departureAirportObj, arrivalAirportObj })
            let arrivalAirportName
            if (arrivalAirportObj?.iata && arrivalAirportObj?.cityName && arrivalAirportObj?.cityEnName && arrivalAirportObj?.countryName && arrivalAirportObj?.countryEnName) {
              departureAirportName = `${arrivalAirportObj.cityName} (${arrivalAirportObj.iata}), ${arrivalAirportObj?.countryName}`
            } else if (arrivalAirportObj.group === 'VIỆT NAM' || arrivalAirportObj.group === 'VIETNAM') {
              arrivalAirportName = `${t(`vnAirports.${segment.arrival.iataCode}`)} (${segment.arrival.iataCode})`
            } else {
              arrivalAirportName = `${arrivalAirportObj.name} (${arrivalAirportObj.iata})`
            }

            const textRoute = t('reservation.route')

            const textItineraries = `* ${textRoute} ${departureAirportName} - ${arrivalAirportName}\n`

            result += isRemoveAccents || !isVietnamese ? removeAccents(textItineraries) : textItineraries

            const textFlight = t('ticket.flightsInfo.flight')
            result += `${textFlight} ${resolveAirlineFlightNumber(segment.airline, segment.flightNumber)}\n`

            const textDepartTime = t('reservation.at')
            const textDepartDate = t('reservation.date')
            const lang = isVietnamese ? 'vi' : 'en'
            const segDepartWeekday = getLongWeekdayFromIso(
              segment.departure.at,
              segment.departure.timeZone,
              lang,
            )
            const segDepartTime = convertISODateTime(
              segment.departure.at,
              segment.departure.timeZone,
            ).time
            const segDepartDate = convertISODateTime(
              segment.departure.at,
              segment.departure.timeZone,
            ).date
            const departWeekday = isVietnamese
              ? t(segDepartWeekday)
              : segDepartWeekday
            result += `${textDepartTime} ${segDepartTime} - ${textDepartDate} ${segDepartDate} (${departWeekday})\n`
          })
        })
        // result += '-------***-------\n'
        // ANCHOR: baggage - a Công bảo bỏ
        // ticketData.forEach(ticket => {
        //   ticket.itineraries.forEach(trip => {
        //     trip.forEach(segment => {
        //       const textHandBaggage = t('ticket.flightsInfo.handBaggage')
        //       const textFreeBaggage = t('ticket.flightsInfo.freeBaggage')
        //       const bag = getBaggageInBookingDetail(segment, ticket)
        //       const carryBagTemp = !isVietnamese && bag.carryBag === 'Không bao gồm' ? 'Not included' : (isVietnamese && bag.carryBag === 'Not included' ? 'Không bao gồm' : bag.carryBag)
        //       result += `${textHandBaggage}: ${segment.departure.iataCode}${segment.arrival.iataCode} ${isRemoveAccents
        //         ? removeAccents(carryBagTemp)
        //         : carryBagTemp}\n`
        //       const checkingBagTemp = !isVietnamese && bag.checkinBag === 'Không bao gồm' ? 'Not included' : (isVietnamese && bag.checkinBag === 'Not included' ? 'Không bao gồm' : bag.checkinBag)
        //       result += `${textFreeBaggage}: ${segment.departure.iataCode}${segment.arrival.iataCode} ${isRemoveAccents
        //         ? removeAccents(checkingBagTemp)
        //         : checkingBagTemp}\n`
        //     })
        //   })
        // })

        // ANCHOR: baggages add on - a Công bảo bỏ
        // ticketData.forEach(ticket => {
        //   if (ticket?.ancillaryServices?.length) {
        //     result += '-------***-------\n'
        //     const textBaggageWithFee = t('reservation.baggageWithFee')
        //     result += `${textBaggageWithFee}:\n`
        //   }
        //   // console.log({ ticket })
        //   if (ticket.source === '1S') {
        //     const bagsAddOn = resolveSsrData(ticket)
        //     // console.log({ bagsAddOn })
        //     let prevPaxId = null
        //     if (bagsAddOn.length) {
        //       // result += `${textBaggageWithFee}:\n`
        //       // result += `${bagsAddOn[0].passenger.lastName} ${bagsAddOn[0].passenger.firstName}\n`
        //       // result += `${bagsAddOn[0].segments[0].departure.iataCode}${bagsAddOn[0].segments[0].arrival.iataCode}: `
        //       bagsAddOn.forEach(item => {
        //         if (item.passenger.paxId !== prevPaxId) {
        //           result += `${item.passenger.lastName} ${item.passenger.firstName}\n`
        //           prevPaxId = item.passenger.paxId
        //         }
        //         // result += `${item.segments[0].departure.iataCode}${item.segments[0].arrival.iataCode}: `
        //         if (item.serviceType === 'BAGGAGE') {
        //           result += `${item.segments[0].departure.iataCode}${item.segments[0].arrival.iataCode}: `
        //           result += `${resolveSsrNameByCode(item.serviceSubCode)} x${item.numberOfItems}\n`
        //         }
        //       })
        //     }
        //   } else {
        //     ticket.itineraries.forEach(trip => {
        //       const segments = resolveSegmentData(trip, ticket)
        //       let prevPaxId

        //       segments.forEach(seg => {
        //         const bagsAddOn = resolveBaggageBySegment(seg)
        //         // console.log({ seg, bagsAddOn })
        //         if (bagsAddOn?.data) {
        //           if (seg.passenger.paxId !== prevPaxId) {
        //             result += `${seg.passenger.lastName} ${seg.passenger.firstName}\n`
        //             prevPaxId = seg.passenger.paxId
        //           }
        //           result += `${seg.segment.departure.iataCode}${seg.segment.arrival.iataCode}: `
        //           bagsAddOn.data.forEach((bag, index) => {
        //             result += `${bag.ssrName || bagsAddOn.text}${bag.quantity || bag.numberOfItems ? ` x${bag.quantity || bag.numberOfItems}` : ''}${index !== bagsAddOn.data.length - 1 ? ', ' : '\n'}`
        //           })
        //         }
        //       })
        //     })
        //   }
        // })

        // ANCHOR: amount payment
        result += '-------***-------\n'
        const textTotalAmount = t('reservation.totalAmount')
        result += `${textTotalAmount}: ${formatCurrency(ticket.fareInfoTicket.total)} VND\n`
        // additional notes
        const textNote1 = t('reservation.sms.note1')
        const textNote2 = t('reservation.sms.note2')
        result += `${textNote1}\n${textNote2}\n\n`
      })

      return result
    }
    // !SECTION

    function setSmsContents() {
      this.$set(smsContents.value, 0, this.getSmsContent(key => this.$t(key, 'vi'), true))
      this.$set(smsContents.value, 1, this.getSmsContent(key => removeAccents(this.$t(key, 'vi')), true, true))
      this.$set(smsContents.value, 2, this.getSmsContent(key => this.$t(key, 'en'), false))
    }

    function onShowModalSms() {
      // setTicketsData()
      this.setSmsContents()
    }

    return {
      tabIndex,
      smsContents,
      disabledCopy,
      // flights,
      // paxs,
      // price,

      copySmsContentToClipboard,
      getAirportByAirportCode,
      getAirlineNameByCode,
      getBaggagesByBookingClass,
      getHandBaggage,
      getFreeBaggage,
      getSmsContent,
      setSmsContents,
      onShowModalSms,
    }
  },
}
</script>

<style lang="scss">
.modal-dialog.modal-reservation-sms {
  width: 640px !important;
  max-width: 640px !important;
}
</style>
