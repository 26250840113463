import { apiBooking } from '@/api/'

export default {
  namespaced: true,
  state: {
    airports: [],
    airportGroup: {},
  },
  getters: {
    getAirportGroup: state => state.airportGroup,
    getAirportByAirportCode: state => airportCode => {
      const result = state.airports?.find(airport => airport.iata === airportCode)
        || state.airportGroup?.airports?.find(
          airport => airport.iata === airportCode,
        )
      return result
    },
  },
  mutations: {
    SET_AIRPORT_GROUP(state, val) {
      state.airportGroup = val
    },
  },
  actions: {
    fetchFlightPreparations(ctx, params) {
      return apiBooking.fetchFlightPreparations(params)
    },
    fetchAirportGroup({ commit }) {
      return new Promise((resolve, reject) => {
        apiBooking
          .fetchAirportGroup()
          .then(response => {
            commit('SET_AIRPORT_GROUP', response.data)
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
