var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "mb-2 box-shadow-0 border p-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "spinner-variant": "warning",
      "rounded": "sm",
      "no-fade": "",
      "variant": "transparent",
      "opacity": 0.5
    }
  }, [_c('flight-preparation-filter', {
    attrs: {
      "hour-preparation-filter": _vm.hourPreparationFilter,
      "hour-preparation-options": _vm.hourPreparationOptions,
      "created-by-filter": _vm.createdByFilter,
      "search-text-filter": _vm.searchTextFilter
    },
    on: {
      "update:hourPreparationFilter": function updateHourPreparationFilter($event) {
        _vm.hourPreparationFilter = $event;
      },
      "update:hour-preparation-filter": function updateHourPreparationFilter($event) {
        _vm.hourPreparationFilter = $event;
      },
      "update:createdByFilter": function updateCreatedByFilter($event) {
        _vm.createdByFilter = $event;
      },
      "update:created-by-filter": function updateCreatedByFilter($event) {
        _vm.createdByFilter = $event;
      },
      "update:searchTextFilter": function updateSearchTextFilter($event) {
        _vm.searchTextFilter = $event;
      },
      "update:search-text-filter": function updateSearchTextFilter($event) {
        _vm.searchTextFilter = $event;
      },
      "fetch-data": _vm.refetchData,
      "reset": _vm.clearFilter
    }
  }), _c('b-table', {
    ref: "refFlightPreparationListTable",
    staticClass: "position-relative p-0 rounded",
    staticStyle: {
      "max-height": "70vh"
    },
    attrs: {
      "sticky-header": true,
      "items": _vm.fetchFlightPreparations,
      "striped": true,
      "hover": true,
      "borderless": true,
      "fields": _vm.visibleFields,
      "sort-desc": _vm.isSortDirDesc,
      "sort-by": _vm.sortBy,
      "responsive": "",
      "no-border-collapse": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "small": ""
    },
    on: {
      "update:sortDesc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.visibleFields, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column.label,
            staticClass: "text-dark text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("flightPreparation.".concat(data.label))) + " ")])];
        }
      };
    }), {
      key: "cell(code)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_c('span', {
          staticClass: "align-text-top text-capitalize font-weight-bold"
        }, [_vm._v(_vm._s(data.item.code))])])];
      }
    }, {
      key: "cell(information)",
      fn: function fn(data) {
        return [_c('b-media', {
          attrs: {
            "vertical-align": "center"
          },
          scopedSlots: _vm._u([{
            key: "aside",
            fn: function fn() {
              return [_c('b-avatar', {
                attrs: {
                  "size": "70px",
                  "src": data.item.photo,
                  "text": _vm.avatarText("".concat(data.item.firstName, " ").concat(data.item.lastName)),
                  "variant": "light-info",
                  "to": {
                    name: 'apps-customers-view',
                    params: {
                      id: data.item.id
                    }
                  }
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('b-media-body', [_c('h5', {
          staticClass: "mb-0"
        }, [_c('b-link', {
          staticClass: "font-weight-bold d-block text-nowrap text-info",
          attrs: {
            "to": {
              name: 'apps-customers-view',
              params: {
                id: data.item.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(data.item.firstName) + " " + _vm._s(data.item.lastName) + " ")])], 1), _c('small', {
          staticClass: "d-block"
        }, [_c('feather-icon', {
          staticClass: "mr-1",
          attrs: {
            "size": "14",
            "icon": "MailIcon"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(data.item.emailAddress) + " ")])], 1), _c('small', {
          staticClass: "d-block"
        }, [_c('feather-icon', {
          staticClass: "mr-1",
          attrs: {
            "size": "14",
            "icon": "PhoneCallIcon"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(data.item.phoneNumber) + " ")])], 1), _c('small', {
          staticClass: "d-block"
        }, [_c('feather-icon', {
          staticClass: "mr-1",
          attrs: {
            "size": "14",
            "icon": "WatchIcon"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(_vm.formatDateMonth(data.item.birthDay)) + " ")])], 1)])], 1)];
      }
    }, {
      key: "cell(createdAt)",
      fn: function fn(data) {
        return [_c('b-media', {
          attrs: {
            "no-body": ""
          }
        }, [_c('b-media-aside', {
          staticClass: "mr-1"
        }, [_c('b-avatar', {
          attrs: {
            "rounded": "",
            "variant": "light-info",
            "size": "34"
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "ClockIcon",
            "size": "18"
          }
        })], 1)], 1), _c('b-media-body', [_c('h6', {
          staticClass: "mb-0 text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.dateTime(data.item.createdAt)) + " ")]), _c('small', [_vm._v(" " + _vm._s(data.item.createdBy ? "".concat(data.item.createdBy.firstName, " ").concat(data.item.createdBy.lastName) : '') + " ")])])], 1)];
      }
    }, {
      key: "cell(timeHold)",
      fn: function fn(data) {
        return [_c('h6', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(_vm.dateTime(data.item.timeHold)) + " ")])];
      }
    }, {
      key: "cell(booker)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "w-100 h-100",
          attrs: {
            "id": "booker-".concat(data.item.createdBy.id, "-").concat(data.item.createdAt)
          }
        }, [_c('small', {
          staticClass: "text-info font-weight-bold text-body-3 text-nowrap"
        }, [_vm._v(" " + _vm._s(data.item.createdBy.username) + " ")]), _c('b-tooltip', {
          attrs: {
            "target": "booker-".concat(data.item.createdBy.id, "-").concat(data.item.createdAt),
            "triggers": "hover focus",
            "placement": "top",
            "boundary": "viewport",
            "variant": "info"
          }
        }, [_c('div', [_vm._v(" " + _vm._s(data.item.createdBy.lastName) + " " + _vm._s(data.item.createdBy.firstName) + " (" + _vm._s(data.item.createdBy.type) + ") ")]), _c('div', [_vm._v(" " + _vm._s(data.item.createdBy.employeeCode) + " ")])])], 1)];
      }
    }, {
      key: "cell(checkInOnline)",
      fn: function fn(data) {
        return _vm._l(new Set(data.item.airlines.split(' | ')), function (airline, index) {
          return _c('div', {
            key: index
          }, [_vm.getInfoCheckinAirline(airline) ? _c('a', {
            staticClass: "d-flex gap-2 align-items-center",
            attrs: {
              "target": "_blank",
              "href": _vm.getInfoCheckinAirline(airline).href
            }
          }, [_c('b-avatar', {
            attrs: {
              "src": _vm.getInfoCheckinAirline(airline).image,
              "size": "20",
              "variant": "transparent"
            }
          }), _c('div', {
            staticClass: "text-nowrap text-body-3"
          }, [_vm._v(_vm._s(_vm.getInfoCheckinAirline(airline).title))])], 1) : _vm._e()]);
        });
      }
    }, {
      key: "cell(bookingCode)",
      fn: function fn(data) {
        return [_c('b-link', {
          staticClass: "font-weight-bold d-block text-nowrap text-info pb-0",
          attrs: {
            "disabled": !_vm.canAccess('booking.detailBooking'),
            "to": {
              name: 'apps-reservations-modify',
              params: {
                id: data.item.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(data.item.bookingCode) + " ")]), data.item.roundtripBooking ? _c('b-button', {
          staticClass: "font-medium-2 py-25 px-50 font-weight-bold",
          attrs: {
            "size": "sm",
            "variant": "info"
          },
          on: {
            "click": data.toggleDetails
          }
        }, [_vm._v(" " + _vm._s(data.item.roundtripBooking.bookingCode) + " ")]) : _vm._e()];
      }
    }, {
      key: "cell(passenger)",
      fn: function fn(data) {
        return _vm._l(data.item.paxLists, function (pax, index) {
          return _c('div', {
            key: index
          }, [_c('small', {
            staticClass: "text-body-3 text-nowrap"
          }, [_vm._v(" " + _vm._s(pax.lastName) + " " + _vm._s(pax.firstName) + " " + _vm._s(pax.title) + " ")])]);
        });
      }
    }, {
      key: "cell(flight)",
      fn: function fn(data) {
        return [data.item.itineraries && data.item.itineraries.length ? _c('div', _vm._l(_vm.resolveBookingRequest(data.item.itineraries), function (trips, index) {
          return _c('div', {
            key: index,
            staticClass: "text-nowrap text-body-3"
          }, _vm._l(trips.segments, function (segment, indexSegment) {
            return _c('div', {
              key: indexSegment,
              staticClass: "d-flex flex-nowrap my-50"
            }, [segment.airline ? _c('IAmLogoAirline', {
              attrs: {
                "airline": segment.operating === 'BL' ? segment.operating : segment.airline,
                "size": "xs",
                "rounded": "",
                "custom-class": "mr-50"
              }
            }) : _vm._e(), _c('span', {
              staticClass: "font-weight-bold",
              staticStyle: {
                "font-size": "18px"
              }
            }, [_vm._v(" " + _vm._s(segment.devSegmentIndex) + " " + _vm._s("".concat(_vm.resolveAirlineFlightNumber(segment.airline, segment.flightNumber))) + " " + _vm._s(segment.bookingClass) + " " + _vm._s(_vm.convertISODateTime(segment.departure.at, segment.departure.timeZone).dayMonth) + " " + _vm._s("".concat(segment.departure.iataCode).concat(segment.arrival.iataCode)) + " " + _vm._s(_vm.convertISODateTime(segment.departure.at, segment.departure.timeZone).hourMin) + " " + _vm._s(_vm.convertISODateTime(segment.arrival.at, segment.arrival.timeZone).hourMin) + " ")])], 1);
          }), 0);
        }), 0) : _c('div', _vm._l(data.item.itineraries.filter(function (trip) {
          return trip.some(function (segment) {
            return segment.status !== 'HX';
          });
        }), function (trip, index) {
          return _c('div', {
            key: index,
            staticClass: "text-nowrap text-body-3"
          }, _vm._l(trip, function (segment, indexSegment) {
            return _c('div', {
              key: indexSegment,
              staticClass: "d-flex flex-nowrap my-50"
            }, [segment.airline ? _c('IAmLogoAirline', {
              attrs: {
                "airline": segment.operating === 'BL' ? segment.operating : segment.airline,
                "size": "xs",
                "rounded": "",
                "custom-class": "mr-50"
              }
            }) : _vm._e(), _c('span', [_vm._v(" " + _vm._s(_vm.convertShortTrip(segment)) + " ")])], 1);
          }), 0);
        }), 0)];
      }
    }, {
      key: "cell(status)",
      fn: function fn(data) {
        return [_c('b-badge', {
          class: "badge-glow round text-uppercase",
          attrs: {
            "variant": "light-".concat(_vm.resolveBookingStatusVariant(data.item.status || data.item.bookingStatus))
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("reservation.".concat(data.item.status))) + " ")]), _c('div', [data.item.timeHold && !data.item.status.toUpperCase().includes('PAID') ? _c('small', [_vm._v(" (" + _vm._s(_vm.convertISODateTime(data.item.timeHold).time) + " " + _vm._s(_vm.convertISODateTime(data.item.timeHold).date) + ") ")]) : _vm._e()])];
      }
    }, {
      key: "cell(action)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex flex-nowrap"
        }, [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }, {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-primary.window",
            modifiers: {
              "hover": true,
              "v-primary": true,
              "window": true
            }
          }],
          staticClass: "btn-icon mr-1 p-50",
          attrs: {
            "variant": "outline-info",
            "title": _vm.isMobileView ? '' : _vm.$t('reservation.exportTicket')
          },
          on: {
            "click": function click($event) {
              return _vm.showPopupTicket(data.item);
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@icons/plane-ticket-blue.svg")
          }
        })]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }, {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-primary.window",
            modifiers: {
              "hover": true,
              "v-primary": true,
              "window": true
            }
          }, {
            name: "b-modal",
            rawName: "v-b-modal.reservation-modify-change-note-modal",
            modifiers: {
              "reservation-modify-change-note-modal": true
            }
          }],
          staticClass: "btn-icon mr-1 p-50",
          attrs: {
            "id": "button-change-note",
            "title": _vm.isMobileView ? '' : 'Chỉnh sửa ghi chú',
            "variant": "outline-info"
          },
          on: {
            "click": function click($event) {
              return _vm.editNote(data.item, $event.target);
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@icons/edit.svg")
          }
        })]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }, {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-primary.window",
            modifiers: {
              "hover": true,
              "v-primary": true,
              "window": true
            }
          }],
          staticClass: "btn-icon mr-1 p-50",
          attrs: {
            "variant": "outline-info",
            "title": _vm.isMobileView ? '' : _vm.$t('reservation.exportSms')
          },
          on: {
            "click": function click($event) {
              return _vm.showPopupSms(data.item);
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@icons/mail.svg")
          }
        })])], 1)];
      }
    }, {
      key: "cell(note)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "two-line-ellipsis text-nowrap"
        }, [_c('div', [_vm._v(_vm._s(data.item.note))]), _c('div', [_vm._v(" " + _vm._s(data.item.autoIssueTime ? "Th\u1EDDi gian xu\u1EA5t v\xE9 t\u1EF1 \u0111\u1ED9ng: ".concat(_vm.convertISODateTime(data.item.autoIssueTime).dateTime) : "") + " ")])])];
      }
    }], null, true)
  }), _c('div', {
    staticClass: "mx-2 mb-2 mt-1"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "d-flex-center justify-content-md-start",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('span', {
    staticClass: "text-muted"
  }, [_vm._v(" " + _vm._s(_vm.$t('paginationText.showing')) + " "), _c('b-dropdown', {
    staticClass: "per-page-selector d-inline-block p-0",
    attrs: {
      "variant": "primary",
      "text": "".concat(_vm.perPage)
    },
    model: {
      value: _vm.perPage,
      callback: function callback($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  }, _vm._l(_vm.sizePerPageLgOptions, function (item) {
    return _c('b-dropdown-item', {
      key: item,
      on: {
        "click": function click($event) {
          _vm.perPage = item;
        }
      }
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 1), _vm._v(" " + _vm._s(_vm.$t('tickets')) + " "), _c('div', [_vm._v(" " + _vm._s(_vm.dataMeta.from) + " " + _vm._s(_vm.$t('paginationText.to')) + " " + _vm._s(_vm.dataMeta.to) + " " + _vm._s(_vm.$t('paginationText.outOf')) + " " + _vm._s(_vm.dataMeta.of) + " " + _vm._s(_vm.$t('tickets')) + " ")])], 1)]), _c('b-col', {
    staticClass: "d-flex-center justify-content-md-end mt-1 mt-md-0 pr-lg-3",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-pagination', {
    staticClass: "mb-0 mt-1 mt-sm-0",
    attrs: {
      "total-rows": _vm.totalFlightPreparations,
      "per-page": _vm.perPage,
      "first-number": "",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item"
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronLeftIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronRightIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1), _c('NoteModal', {
    attrs: {
      "reservation-data": _vm.reservationData
    },
    on: {
      "update:reservationData": function updateReservationData($event) {
        _vm.reservationData = $event;
      },
      "update:reservation-data": function updateReservationData($event) {
        _vm.reservationData = $event;
      },
      "refetch-data": _vm.refetchData
    }
  }), _vm.ticketData ? _c('ModalSms', {
    attrs: {
      "ticket-data": _vm.ticketData
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }