var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "id-modal-reservation-sms",
      "title": _vm.$t('reservation.exportSms'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "body-class": "p-1",
      "centered": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "show": function show($event) {
        return _vm.onShowModalSms();
      }
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(234, 84, 85, 0.15)',
            expression: "'rgba(234, 84, 85, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          attrs: {
            "variant": "outline-danger",
            "pill": ""
          },
          on: {
            "click": function click($event) {
              return _vm.$root.$emit('bv::hide::modal', 'id-modal-reservation-sms');
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('customer.cancel')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-gradient width-150 mt-lg-25",
          attrs: {
            "pill": "",
            "disabled": _vm.disabledCopy
          },
          on: {
            "click": function click($event) {
              return _vm.copySmsContentToClipboard();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.copy')) + " ")])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "text-danger"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.sms.note')) + " ")]), _c('b-tabs', {
    attrs: {
      "lazy": ""
    },
    model: {
      value: _vm.tabIndex,
      callback: function callback($$v) {
        _vm.tabIndex = $$v;
      },
      expression: "tabIndex"
    }
  }, [_c('b-tab', {
    attrs: {
      "active": "",
      "title": _vm.$t('reservation.sms.vnWithAccent')
    }
  }, [_c('b-form-textarea', {
    staticStyle: {
      "font-size": "16px",
      "line-height": "24px"
    },
    attrs: {
      "rows": "16"
    },
    model: {
      value: _vm.smsContents[0],
      callback: function callback($$v) {
        _vm.$set(_vm.smsContents, 0, $$v);
      },
      expression: "smsContents[0]"
    }
  })], 1), _c('b-tab', {
    attrs: {
      "title": _vm.$t('reservation.sms.vnNoAccent')
    }
  }, [_c('b-form-textarea', {
    staticStyle: {
      "font-size": "16px",
      "line-height": "24px"
    },
    attrs: {
      "rows": "16"
    },
    model: {
      value: _vm.smsContents[1],
      callback: function callback($$v) {
        _vm.$set(_vm.smsContents, 1, $$v);
      },
      expression: "smsContents[1]"
    }
  })], 1), _c('b-tab', {
    attrs: {
      "title": _vm.$t('reservation.sms.en')
    }
  }, [_c('b-form-textarea', {
    staticStyle: {
      "font-size": "16px",
      "line-height": "24px"
    },
    attrs: {
      "rows": "16"
    },
    model: {
      value: _vm.smsContents[2],
      callback: function callback($$v) {
        _vm.$set(_vm.smsContents, 2, $$v);
      },
      expression: "smsContents[2]"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }