import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'

import store from '@/store'

// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useFlightPreparationList() {
  // Use toast
  const toast = useToast()

  const refFlightPreparationListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { label: 'source', key: 'source' },
    { label: 'bookingCode', key: 'bookingCode' },
    { label: 'status', key: 'status' },
    { label: 'passenger', key: 'passenger' },
    { label: 'booker', key: 'booker' },
    { label: 'flight', key: 'flight' },
    { label: 'createdAt', key: 'createdAt' },
    { label: 'checkInOnline', key: 'checkInOnline' },
    { label: 'action', key: 'action' },
    { label: 'note', key: 'note' },
  ]

  const perPage = ref(10)
  const totalFlightPreparations = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchTextFilter = ref(null)
  const createdByFilter = ref(null)
  const sortBy = ref('createdAt')
  const isSortDirDesc = ref(true)
  const hourPreparationFilter = ref(72)

  const loading = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refFlightPreparationListTable.value
      ? refFlightPreparationListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalFlightPreparations.value,
    }
  })

  const refetchData = () => {
    refFlightPreparationListTable.value.refresh()
  }

  watch(
    [currentPage, perPage, searchTextFilter, hourPreparationFilter, createdByFilter],
    () => {
      refetchData()
    },
  )

  const fetchFlightPreparations = (ctx, callback) => {
    loading.value = true
    store
      .dispatch('app-flight-preparation/fetchFlightPreparations', {
        size: perPage.value,
        page: currentPage.value,
        hourPreparation: (hourPreparationFilter.value + 1) * 24,
        status: 'PAID',
        ...(createdByFilter.value && { createdById: createdByFilter.value.id }),
        ...(searchTextFilter.value && { searchText: searchTextFilter.value }),
      })
      .then(response => {
        totalFlightPreparations.value = response.data.count
        const customers = response.data
        callback(customers.items)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching flight preparation list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .finally(() => {
        loading.value = false
      })
  }

  const fetchAirportGroup = () => new Promise((resolve, reject) => {
    store
      .dispatch('app-flight-preparation/fetchAirportGroup')
      .then(response => resolve(response))
      .catch(error => reject(error))
  })

  const clearFilter = () => {
    searchTextFilter.value = null
    createdByFilter.value = null
    hourPreparationFilter.value = 2
    refetchData()
  }

  return {
    fetchFlightPreparations,
    tableColumns,
    perPage,
    currentPage,
    totalFlightPreparations,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refetchData,
    fetchAirportGroup,
    refFlightPreparationListTable,
    hourPreparationFilter,
    loading,

    searchTextFilter,
    createdByFilter,
    clearFilter,
  }
}
