<template>
  <!-- Table Container Card -->
  <b-card
    no-body
    class="mb-2 box-shadow-0 border p-1"
  >
    <b-overlay
      :show="loading"
      spinner-variant="warning"
      rounded="sm"
      no-fade
      variant="transparent"
      :opacity="0.5"
    >
      <!-- Filters -->
      <flight-preparation-filter
        :hour-preparation-filter.sync="hourPreparationFilter"
        :hour-preparation-options="hourPreparationOptions"
        :created-by-filter.sync="createdByFilter"
        :search-text-filter.sync="searchTextFilter"
        @fetch-data="refetchData"
        @reset="clearFilter"
      />

      <b-table
        ref="refFlightPreparationListTable"
        style="max-height: 70vh"
        :sticky-header="true"
        :items="fetchFlightPreparations"
        :striped="true"
        class="position-relative p-0 rounded"
        :hover="true"
        :borderless="true"
        :fields="visibleFields"
        :sort-desc.sync="isSortDirDesc"
        :sort-by.sync="sortBy"
        responsive
        no-border-collapse
        :empty-text="$t('noMatchingResult')"
        small
      >
        <!-- COLUMNS HEADER -->
        <template
          v-for="column in visibleFields"
          #[`head(${column.key})`]="data"
        >
          <span
            :key="column.label"
            class="text-dark text-nowrap"
          >
            {{ $t(`flightPreparation.${data.label}`) }}
          </span>
        </template>
        <template #cell(code)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize font-weight-bold">{{
              data.item.code
            }}</span>
          </div>
        </template>

        <template #cell(information)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="70px"
                :src="data.item.photo"
                :text="avatarText(`${data.item.firstName} ${data.item.lastName}`)"
                variant="light-info"
                :to="{
                  name: 'apps-customers-view',
                  params: { id: data.item.id },
                }"
              />
            </template>
            <b-media-body>
              <h5 class="mb-0">
                <b-link
                  :to="{
                    name: 'apps-customers-view',
                    params: { id: data.item.id },
                  }"
                  class="font-weight-bold d-block text-nowrap text-info"
                >
                  {{ data.item.firstName }} {{ data.item.lastName }}
                </b-link>
              </h5>
              <small class="d-block">
                <feather-icon
                  size="14"
                  icon="MailIcon"
                  class="mr-1"
                />
                <span class="font-weight-bold">
                  {{ data.item.emailAddress }}
                </span>
              </small>
              <small class="d-block">
                <feather-icon
                  size="14"
                  icon="PhoneCallIcon"
                  class="mr-1"
                />
                <span class="font-weight-bold">
                  {{ data.item.phoneNumber }}
                </span>
              </small>
              <small class="d-block">
                <feather-icon
                  size="14"
                  icon="WatchIcon"
                  class="mr-1"
                />
                <span class="font-weight-bold">
                  {{ formatDateMonth(data.item.birthDay) }}
                </span>
              </small>
            </b-media-body>
          </b-media>
        </template>

        <template #cell(createdAt)="data">
          <b-media no-body>
            <b-media-aside class="mr-1">
              <b-avatar
                rounded
                variant="light-info"
                size="34"
              >
                <feather-icon
                  icon="ClockIcon"
                  size="18"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="mb-0 text-nowrap">
                {{ dateTime(data.item.createdAt) }}
              </h6>
              <small>
                {{
                  data.item.createdBy
                    ? `${data.item.createdBy.firstName} ${data.item.createdBy.lastName}`
                    : ''
                }}
              </small>
            </b-media-body>
          </b-media>
        </template>

        <template #cell(timeHold)="data">
          <h6 class="mb-0">
            {{ dateTime(data.item.timeHold) }}
          </h6>
        </template>

        <template #cell(booker)="data">
          <div
            :id="`booker-${data.item.createdBy.id}-${data.item.createdAt}`"
            class="w-100 h-100"
          >
            <small class="text-info font-weight-bold text-body-3 text-nowrap">
              {{ data.item.createdBy.username }}
            </small>
            <b-tooltip
              :target="`booker-${data.item.createdBy.id}-${data.item.createdAt}`"
              triggers="hover focus"
              placement="top"
              boundary="viewport"
              variant="info"
            >
              <div>
                {{ data.item.createdBy.lastName }}
                {{ data.item.createdBy.firstName }}
                ({{ data.item.createdBy.type }})
              </div>
              <div>
                {{ data.item.createdBy.employeeCode }}
              </div>
            </b-tooltip>
          </div>
        </template>

        <template #cell(checkInOnline)="data">
          <div
            v-for="(airline, index) of new Set(data.item.airlines.split(' | '))"
            :key="index"
          >
            <a
              v-if="getInfoCheckinAirline(airline)"
              target="_blank"
              :href="getInfoCheckinAirline(airline).href"
              class="d-flex gap-2 align-items-center"
            >
              <b-avatar
                :src="getInfoCheckinAirline(airline).image"
                size="20"
                variant="transparent"
              />
              <div class="text-nowrap text-body-3">{{ getInfoCheckinAirline(airline).title }}</div>
            </a>
          </div>
        </template>

        <template #cell(bookingCode)="data">
          <b-link
            :disabled="!canAccess('booking.detailBooking')"
            :to="{ name: 'apps-reservations-modify', params: { id: data.item.id } }"
            class="font-weight-bold d-block text-nowrap text-info pb-0"
          >
            {{ data.item.bookingCode }}
          </b-link>
          <b-button
            v-if="data.item.roundtripBooking"
            size="sm"
            variant="info"
            class="font-medium-2 py-25 px-50 font-weight-bold"
            @click="data.toggleDetails"
          >
            {{ data.item.roundtripBooking.bookingCode }}
          </b-button>
        </template>

        <template #cell(passenger)="data">
          <div
            v-for="(pax, index) of data.item.paxLists"
            :key="index"
          >
            <small class="text-body-3 text-nowrap">
              {{ pax.lastName }} {{ pax.firstName }} {{ pax.title }}
            </small>
          </div>
        </template>

        <template #cell(flight)="data">
          <div v-if="data.item.itineraries && data.item.itineraries.length">
            <div
              v-for="(trips, index) of resolveBookingRequest(data.item.itineraries)"
              :key="index"
              class="text-nowrap text-body-3"
            >
              <div
                v-for="(segment, indexSegment) in trips.segments"
                :key="indexSegment"
                class="d-flex flex-nowrap my-50"
              >
                <IAmLogoAirline
                  v-if="segment.airline"
                  :airline="segment.operating === 'BL' ? segment.operating : segment.airline"
                  size="xs"
                  rounded
                  custom-class="mr-50"
                />
                <span
                  class="font-weight-bold"
                  style="font-size: 18px"
                >
                  {{ segment.devSegmentIndex }}
                  {{ `${resolveAirlineFlightNumber(segment.airline, segment.flightNumber)}` }}
                  {{ segment.bookingClass }}
                  {{ convertISODateTime(segment.departure.at, segment.departure.timeZone).dayMonth }}
                  {{ `${segment.departure.iataCode}${segment.arrival.iataCode}` }}
                  {{ convertISODateTime(segment.departure.at, segment.departure.timeZone).hourMin }}
                  {{ convertISODateTime(segment.arrival.at, segment.arrival.timeZone).hourMin }}
                </span>
              </div>
            </div>
          </div>

          <div v-else>
            <div
              v-for="(trip, index) of data.item.itineraries.filter(trip => trip.some(segment => segment.status !== 'HX'))"
              :key="index"
              class="text-nowrap text-body-3"
            >
              <div
                v-for="(segment, indexSegment) in trip"
                :key="indexSegment"
                class="d-flex flex-nowrap my-50"
              >
                <IAmLogoAirline
                  v-if="segment.airline"
                  :airline="segment.operating === 'BL' ? segment.operating : segment.airline"
                  size="xs"
                  rounded
                  custom-class="mr-50"
                />
                <span>
                  {{ convertShortTrip(segment) }}
                </span>
              </div>
            </div>
          </div>
        </template>

        <template #cell(status)="data">
          <b-badge
            :variant="`light-${resolveBookingStatusVariant(data.item.status || data.item.bookingStatus)}`"
            :class="`badge-glow round text-uppercase`"
          >
            {{ $t(`reservation.${data.item.status}`) }}
          </b-badge>
          <div>
            <small v-if="data.item.timeHold && !data.item.status.toUpperCase().includes('PAID')">
              ({{ convertISODateTime(data.item.timeHold).time }}
              {{ convertISODateTime(data.item.timeHold).date }})
            </small>
          </div>
        </template>

        <template #cell(action)="data">
          <div class="d-flex flex-nowrap">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.v-primary.window
              variant="outline-info"
              :title="isMobileView ? '' : $t('reservation.exportTicket')"
              class="btn-icon mr-1 p-50"
              @click="showPopupTicket(data.item)"
            >
              <img src="@icons/plane-ticket-blue.svg">
            </b-button>

            <b-button
              id="button-change-note"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.v-primary.window
              v-b-modal.reservation-modify-change-note-modal
              :title="isMobileView ? '' : 'Chỉnh sửa ghi chú'"
              variant="outline-info"
              class="btn-icon mr-1 p-50"
              @click="editNote(data.item, $event.target)"
            >
              <!-- <feather-icon icon="Edit2Icon" /> -->
              <img src="@icons/edit.svg">
            </b-button>

            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.v-primary.window
              variant="outline-info"
              :title="isMobileView ? '' : $t('reservation.exportSms')"
              class="btn-icon mr-1 p-50"
              @click="showPopupSms(data.item)"
            >
              <img src="@icons/mail.svg">
            </b-button>
          </div>
        </template>

        <template #cell(note)="data">
          <div class="two-line-ellipsis text-nowrap">
            <div>{{ data.item.note }}</div>
            <div>
              {{ data.item.autoIssueTime ? `Thời gian xuất vé tự động:
              ${convertISODateTime(data.item.autoIssueTime).dateTime}` : "" }}
            </div>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2 mt-1">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex-center justify-content-md-start"
          >
            <span class="text-muted">
              {{ $t('paginationText.showing') }}
              <b-dropdown
                v-model="perPage"
                variant="primary"
                :text="`${perPage}`"
                class="per-page-selector d-inline-block p-0"
              >
                <b-dropdown-item
                  v-for="item in sizePerPageLgOptions"
                  :key="item"
                  @click="perPage = item"
                >
                  {{ item }}
                </b-dropdown-item>
              </b-dropdown>
              {{ $t('tickets') }}
              <div>
                {{ dataMeta.from }} {{ $t('paginationText.to') }} {{ dataMeta.to }} {{ $t('paginationText.outOf') }} {{
                  dataMeta.of }} {{ $t('tickets') }}
              </div>
            </span>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex-center justify-content-md-end mt-1 mt-md-0 pr-lg-3"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalFlightPreparations"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

      <NoteModal
        :reservation-data.sync="reservationData"
        @refetch-data="refetchData"
      />

      <ModalSms
        v-if="ticketData"
        :ticket-data="ticketData"
      />
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BMediaAside,
  BMediaBody,
  BPagination,
  BOverlay,
  BTooltip,
  VBTooltip,
  BButton,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import isEmpty from 'lodash/isEmpty'
import { ref, computed, onUnmounted } from '@vue/composition-api'

import {
  resolveBookingStatusVariant,
  sizePerPageLgOptions,
} from '@/constants/selectOptions'
import { logoAirlineUrl, useRouter } from '@/@core/utils/utils'
import store from '@/store'

import {
  dateTime, convertISODateTime,
  convertShortTrip, resolveAirlineFlightNumber,
} from '@core/utils/filter'

import NoteModal from '@reservation/reservation-list/NoteModal.vue'
import reservationStoreModule from '@reservation/reservationStoreModule'

import FlightPreparationFilter from './FlightPreparationFilter.vue'
import useFlightPreparationList from './useFlightPreparationList'
import FlightPreparationStoreModule from './FlightPreparationStoreModule'
import ModalSms from '../ModalSms.vue'

export default {
  directives: { 'b-tooltip': VBTooltip },
  components: {
    NoteModal,
    FlightPreparationFilter,
    BMediaAside,
    BMediaBody,
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
    BOverlay,
    BTooltip,
    BButton,
    BDropdown,
    BDropdownItem,

    ModalSms,
    IAmLogoAirline: () => import('@/components/IAmLogoAirline.vue'),
  },
  setup() {
    const hourPreparationOptions = [
      { label: '24h', value: 24 },
      { label: '48h', value: 48 },
      { label: '72h', value: 72 },
    ]

    const FLIGHT_PREPARATION_APP_STORE_MODULE_NAME = 'app-flight-preparation'

    // Register module
    if (!store.hasModule(FLIGHT_PREPARATION_APP_STORE_MODULE_NAME)) {
      store.registerModule(FLIGHT_PREPARATION_APP_STORE_MODULE_NAME, FlightPreparationStoreModule)
    }

    // Register module
    if (!store.hasModule('app-reservation')) {
      store.registerModule('app-reservation', reservationStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FLIGHT_PREPARATION_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(FLIGHT_PREPARATION_APP_STORE_MODULE_NAME)
      }
      if (store.hasModule('app-reservation')) {
        store.unregisterModule('app-reservation')
      }
    })

    const reservationData = ref({
      note: '',
    })
    const ticketData = ref({})
    const { route } = useRouter()
    const fullPath = computed(() => route.value.fullPath)

    function resolveBookingRequest(trips) {
      let indexSegment = 1
      return trips.map(trip => ({
        ...trip,
        segments: trip.map(segment => {
          const data = {
            ...segment,
            devSegmentIndex: String(indexSegment),
          }

          indexSegment += 1
          return data
        }),
      }))
    }

    async function showPopupTicket(item) {
      await new Promise(resolve => {
        ticketData.value = item
        resolve()
      })
      // this.$root.$emit('bv::show::modal', 'id-modal-reservation-ticket')
      const { id, source, bookingCode } = ticketData.value
      const statusBooking = ['Hold', 'Paid', 'Cancel', 'Expired']
      const isPathHaveStatus = statusBooking.some(status => fullPath.value.includes(status))
      const path = `${!isPathHaveStatus ? 'bookings/' : ''}${id || `${source}-${bookingCode}`}/ticket`
      const route = this.$router.resolve({ path })
      window.open(route.href)
    }

    async function showPopupSms(item) {
      await new Promise(resolve => {
        ticketData.value = item
        resolve()
      })
      console.log(item)
      this.$root.$emit('bv::show::modal', 'id-modal-reservation-sms')
    }

    async function editNote(reservation, event) {
      await new Promise(resolve => {
        reservationData.value = reservation
        resolve()
      })
      this.$root.$emit('bv::show::modal', 'reservation-modify-change-note-modal', event)
    }

    function getInfoCheckinAirline(airline) {
      return [
        {
          airline: 'VJ',
          title: 'Vietjet Air',
          href: 'https://www.vietjetair.com/vi/checkin',
          image: logoAirlineUrl('VJ'),
        },
        {
          airline: 'QH',
          title: 'Bamboo Airways',
          href: 'https://digital.bambooairways.com/ssci/identification?lang=vi',
          image: logoAirlineUrl('QH'),
        },
        {
          airline: 'VN',
          title: 'Vietnam Airlines',
          href: 'https://dxcheckin.vietnamairlines.com/dx/VNCI/#/check-in/start?locale=vi-VN',
          image: logoAirlineUrl('VN'),
        },
        {
          airline: 'VU',
          title: 'Vietravel Airlines',
          href: 'https://booking.vietravelairlines.com/vi/checkin',
          image: logoAirlineUrl('VU'),
        },
      ].find(item => item.airline === airline) || ''
    }

    const {
      fetchFlightPreparations,
      tableColumns,
      perPage,
      currentPage,
      totalFlightPreparations,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      fetchAirportGroup,
      refFlightPreparationListTable,
      hourPreparationFilter,
      loading,
      searchTextFilter,
      createdByFilter,
      clearFilter,
    } = useFlightPreparationList()

    if (isEmpty(store.getters['app-flight-preparation/getAirportGroup'])) { fetchAirportGroup() }
    const visibleFields = computed(() => hourPreparationFilter.value !== 0 ? tableColumns.filter(item => item.key !== 'checkInOnline') : tableColumns)

    return {
      fetchFlightPreparations,
      visibleFields,
      perPage,
      currentPage,
      totalFlightPreparations,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refFlightPreparationListTable,
      hourPreparationOptions,
      hourPreparationFilter,
      loading,

      dateTime,
      resolveBookingRequest,
      convertISODateTime,
      resolveBookingStatusVariant,
      convertShortTrip,
      sizePerPageLgOptions,

      reservationData,
      ticketData,
      showPopupTicket,
      showPopupSms,
      editNote,
      getInfoCheckinAirline,

      searchTextFilter,
      createdByFilter,
      clearFilter,
      resolveAirlineFlightNumber,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
