<template>
  <b-card no-body>
    <b-card-body class="d-lg-flex">
      <b-row class="flex-grow-1">
        <!-- ANCHOR: HOUR PREPARATION -->
        <b-col
          cols="12"
          md="4"
          class="mb-1 mb-md-0"
        >
          <label class="font-weight-bolder">{{ $t('flightPreparation.hourPreparation') }}</label>
          <b-tabs
            id="hour-preparation-tabs-class"
            :value="hourPreparationFilter"
            pills
            fill
            class="w-100"
            nav-class="mb-0"
            active-nav-item-class="tab-active"
            @input="(val) => $emit('update:hourPreparationFilter', val)"
          >
            <b-tab
              v-for="(item, index) in hourPreparationOptions"
              :key="index"
              class="bg-.nav-pills .nav-link.active"
            >
              <template #title>
                {{ item.label }}
              </template>
            </b-tab>
          </b-tabs>
        </b-col>

        <!-- ANCHOR: search text -->
        <b-col
          cols="12"
          md="4"
          class="mb-1 mb-md-0 flex-grow-1"
          style="translate: 0 3px;"
        >
          <!-- ANCHOR searchText -->
          <label class="font-weight-bolder">{{ $t('flightPreparation.searchText') }}</label>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              :value="searchTextFilter"
              type="search"
              maxlength="255"
              debounce="100"
              :disabled="!canAccess('booking.searchBooking')"
              trim
              placeholder="Mã đặt chỗ, tên hành khách, hành trình"
              @input="(value) => handleDebounceSearchText(value)"
            />
          </b-input-group>
        </b-col>

        <!-- ANCHOR created by -->
        <b-col
          cols="12"
          md="4"
          class="mb-1 mb-md-0 flex-grow-1"
          style="translate: 0 3px; z-index: 4;"
        >
          <label class="font-weight-bolder mb-25">{{ $t('createdBy') }}</label>
          <v-select
            id="created-by-select"
            :options="createdByOptions"
            label="firstName"
            class="form-control p-0 border-0"
            searchable
            :clearable="false"
            :filterable="false"
            :value="createdByFilter"
            :loading="isLoading"
            :placeholder="$t('placeholderSelect')"
            :reduce="val => val"
            @open="handleOpenCreatedBy"
            @search="handleSearchCreatedBy"
            @input="(val) => $emit('update:createdByFilter', val)"
          >
            <template #selected-option="{firstName, lastName, username}">
              <div class="w-100 d-flex justify-content-between text-truncate">
                <span
                  class="font-weight-bold d-block text-nowrap"
                  :class="isMobileView ? 'font-small-2' : ''"
                >
                  {{ lastName }} {{ firstName }} ({{ username }})
                </span>
              </div>
            </template>
            <template #spinner="{ loading }">
              <div
                v-if="loading"
                style="border-left-color: rgba(88, 151, 251, 0.71)"
                class="vs__spinner"
              />
            </template>
            <template v-slot:option="{firstName, lastName, username}">
              <b-row>
                <b-col
                  cols="12"
                  md="9"
                >
                  <span
                    class="font-weight-bold d-block text-nowrap"
                    :class="isMobileView ? 'font-small-1' : ''"
                  >
                    {{ lastName }} {{ firstName }} ({{ username }})
                  </span>
                </b-col>
              </b-row>
            </template>
          </v-select>
        </b-col>
      </b-row>
      <!-- ANCHOR button -->
      <div class="ml-lg-1 d-flex-center align-items-end">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          class="font-weight-bolder mt-md-0"
          variant="outline-danger"
          @click="$emit('reset')"
        >
          <feather-icon
            icon="TrashIcon"
            class="mr-50 text-danger"
          />
          <span
            class="align-middle text-danger font-weight-bolder"
            style=""
          >{{ $t('clear') }}
          </span>
        </b-button>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BRow, BCol,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput, BButton,
  BTabs, BTab,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { debounce } from 'lodash'
import { ref } from '@vue/composition-api'

import api from '@/api'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BButton,
    BTabs,
    BTab,

    vSelect,
  },
  props: {
    createdByFilter: {
      type: [Object, null],
      default: () => {},
    },
    searchTextFilter: {
      type: String,
      default: '',
    },
    airlineFilter: {
      type: [String, null],
      default: null,
    },
    airlineOptions: {
      type: Array,
      default: () => [],
    },
    hourPreparationFilter: {
      type: Number,
      default: 2,
    },
    hourPreparationOptions: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getSuggestionValue(suggestion) {
      return suggestion.item.value
    },
  },
  setup(_, { emit }) {
    const handleDebounceSearchText = debounce(searchText => {
      emit('update:searchTextFilter', searchText)
    }, 100)

    const createdByOptions = ref([])
    const isLoading = ref(false)
    const createdByOptionsDefault = ref([])

    const fetchEmployeesByFilterSearch = async (search = '') => {
      createdByOptions.value = []
      isLoading.value = true
      try {
        const data = await api.employee.fetchEmployeesByFilter({ searchText: search })
        if (data && !search) {
          createdByOptionsDefault.value = data.items
        }
        createdByOptions.value = data?.items || []
      } catch (error) {
        console.error(error)
      } finally {
        isLoading.value = false
      }
    }

    function handleOpenCreatedBy() {
      if (createdByOptionsDefault.value.length === 0) {
        fetchEmployeesByFilterSearch()
      } else {
        createdByOptions.value = createdByOptionsDefault.value
      }
    }

    const handleSearchCreatedBy = debounce(search => {
      if (search) fetchEmployeesByFilterSearch(search)
    }, 100)

    return {
      handleDebounceSearchText,
      handleOpenCreatedBy,
      handleSearchCreatedBy,
      createdByOptions,
      isLoading,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
#hour-preparation-tabs-class ::v-deep {
  .nav-pills .nav-link {
    font-size: 16px;
    color: #3DA5DE;
    border: 1px solid #3DA5DE;
    border-radius: 0.358rem;
    margin: 0 4px;
    &.active {
      color: white;
    }
  }
}
</style>
